<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2 class="page-title tw-flex tw-items-center tw-mb-1">
              <span>Calendar Management</span>
              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  Automatically create new calendars and hide them when the
                  leave year finishes.
                </div>
              </ExtraInfo>
            </h2>
            <p class="tw-mt-1">Calendars are created at midnight UTC</p>
          </div>
        </div>
      </div>

      <form class="tw-w-full" @submit.prevent="submit">
        <div class="form-group">
          <div class="tw-w-full tw-flex tw-items-center">
            <ToggleButton
              :value="calendarSettings.manage_automatically"
              :sync="true"
              :labels="true"
              class="tw-mr-4"
              color="#1da1f2"
              @change="toggleCreateAutomaticCalendar($event.value)"
            />
            <span>Enable automatic management of calendars</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label tw-mb-4" for="create_before_months">
            Create new calendars
          </label>
          <div class="tw-w-full tw-flex tw-items-center">
            <input
              id="create_before_months"
              v-model="calendarSettings.create_before_months"
              v-validate="'required|numeric'"
              :disabled="!calendarSettings.manage_automatically"
              name="create_before_months"
              data-vv-as="months in advance"
              class="form-control tw-mr-4 tw-w-24"
              type="number"
              min="1"
              max="120"
              inputmode="numeric"
            />
            <div>
              Months in advance <span class="required-field">&#42;</span>
            </div>
          </div>
          <p
            v-show="errors.has('create_before_months')"
            class="tw-mt-3 tw-text-red-700 tw-text-sm"
          >
            Enter a whole number between 1 and 120.
          </p>
        </div>

        <div class="form-group">
          <label class="form-label tw-mb-4" for="hide_after_months">
            Hide old calendars
          </label>
          <div class="tw-w-full tw-flex tw-items-center">
            <input
              id="hide_after_months"
              v-model="calendarSettings.hide_after_months"
              v-validate="'required|numeric'"
              :disabled="!calendarSettings.manage_automatically"
              name="hide_after_months"
              data-vv-as="months after end"
              class="form-control tw-mr-4 tw-w-24"
              type="number"
              min="1"
              max="120"
              inputmode="numeric"
            />
            <div>
              Months after they end <span class="required-field">&#42;</span>
            </div>
          </div>
          <p
            v-show="errors.has('hide_after_months')"
            class="tw-mt-3 tw-text-red-700 tw-text-sm"
          >
            Enter a whole number between 1 and 120.
          </p>
        </div>

        <SpinnerButton
          :disabled="
            !valid || loading || !calendarSettings.manage_automatically
          "
          :loading="loading"
          :spinner-only="true"
          class="tw-mb-6"
          type="submit"
        >
          Save
        </SpinnerButton>
      </form>
    </div>

    <div class="card">
      <div class="tw-pb-10 tw-pt-3">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2 class="page-title tw-flex tw-items-center">
              <span>Calendars</span>
              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  Calendars are used to store the allowances of employees. As
                  you approach the end of the leave year, you will need to
                  create a new calendar for next year.
                  <div>
                    <a
                      class="btn-link tw-font-semibold"
                      target="_blank"
                      rel="noopener"
                      href="https://help.leavedates.com/hc/en-us/articles/360002594319-Create-a-new-calendar-for-next-year"
                      >More info</a
                    >
                  </div>
                </div>
              </ExtraInfo>
            </h2>
          </div>
        </div>
      </div>

      <div class="tw-flex tw-flex-wrap tw--mx-4">
        <template v-if="calendars.length()">
          <div
            v-for="calendar in calendars.all()"
            :key="calendar.id"
            class="tw-w-full sm:tw-w-1/2 md:tw-w-1/3 lg:tw-w-1/5"
          >
            <CalendarCard
              :calendar-data="calendar"
              :deleting-calendar="deletingCalendar"
              :disabled="isUpdatingCalendar || !!deletingCalendar"
              data-cy="calendar-card"
              @update="update"
              @delete="destroy"
            />
          </div>
        </template>

        <div class="tw-w-full sm:tw-w-1/2 md:tw-w-1/3 lg:tw-w-1/5">
          <div
            class="tw-flex tw-relative tw-mx-4 tw-mb-8 tw-bg-white tw-rounded-lg tw-overflow-hidden tw-border-2 tw-border-dashed tw-border-gray-300"
            style="min-height: 14.6rem"
          >
            <CalendarStartDatePicker
              v-if="isSelectingStartDate"
              :calendars="calendars"
              @date-picked="createCalendar"
              @date-pick-cancelled="hideStartDateSelector"
            />

            <SpinnerButton
              v-else
              :disabled="isCreatingCalendar"
              :loading="isCreatingCalendar"
              class="tw-absolute tw-w-full tw-h-full group"
              :spinner-only="true"
              :spinner-classes="['tw-h-12 tw-w-12 tw-text-gray-500']"
              theme="transparent"
              type="button"
              data-cy="add-calendar"
              @click="showStartDateSelector"
            >
              <SvgIcon
                name="add-outline"
                class="tw-w-12 tw-h-12 tw-cursor-pointer tw-text-gray-500 group-hover:tw-text-blue-500"
              />
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendars, CalendarSettings } from '@/api'
import { mapActions } from 'vuex'
import ExtraInfo from '@/components/ExtraInfo'
import ValidatesForm from '@/mixins/ValidatesForm'
import { ToggleButton } from 'vue-js-toggle-button'
import CalendarCard from '@/components/CalendarCard'
import SpinnerButton from '@/components/SpinnerButton'
import CalendarShortcuts from '@/mixins/page-shortcuts/settings/CalendarsShortcuts'
import CalendarStartDatePicker from '@/components/calendars/CalendarStartDatePicker'
import CalendarCollection from '@/models/company/CalendarCollection'

export default {
  name: 'Calendars',

  components: {
    CalendarStartDatePicker,
    ExtraInfo,
    CalendarCard,
    ToggleButton,
    SpinnerButton,
  },

  mixins: [CalendarShortcuts, ValidatesForm],

  data: () => ({
    loading: false,
    isCreatingCalendar: false,
    isUpdatingCalendar: false,
    deletingCalendar: null,
    calendars: new CalendarCollection(),
    calendarSettings: {
      manage_automatically: true,
      create_before_months: 12,
      hide_after_months: 3,
    },
    isSelectingStartDate: false,
  }),

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchCalendars()
        this.fetchCalendarSettings()
      },
    },
  },

  created() {
    this.fetchCalendarSettings()
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    showStartDateSelector() {
      this.isSelectingStartDate = true
    },

    hideStartDateSelector() {
      this.isSelectingStartDate = false
    },

    fetchCalendarSettings() {
      this.calendarSettings = this.activeCompany.calendar_settings
    },

    async toggleCreateAutomaticCalendar(value) {
      this.calendarSettings.manage_automatically = value

      this.loading = true

      value
        ? await this.saveAutomaticCalendarSettings()
        : await this.disableAutomaticCalendarCreation()

      await this.fetchUser()

      const status = value ? 'enabled' : 'disabled'
      this.success(`Automatic calender ${status} successfully.`)

      this.loading = false
    },

    async saveAutomaticCalendarSettings() {
      return CalendarSettings.update(this.activeCompany, this.calendarSettings)
    },

    async disableAutomaticCalendarCreation() {
      return CalendarSettings.delete(this.activeCompany)
    },

    async fetchCalendars() {
      try {
        this.calendars = await Calendars.all(this.$route.query)
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async createCalendar(startDate) {
      this.isSelectingStartDate = false
      this.isCreatingCalendar = true

      try {
        await Calendars.create({
          company_id: this.activeCompany.id,
          start_date: startDate.format('YYYY-MM-DD'),
        })

        await this.fetchCalendars()

        this.fetchUser()

        const calendarYearText = this.getCalendarYearText(startDate)

        this.success(
          `Created the ${calendarYearText}.. Started to create allowances for employments. This might take a few minutes.
          We will send you a notification when it's done.`
        )
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.isCreatingCalendar = false
    },

    getCalendarYearText(startDate) {
      const firstDayOfYear = startDate.clone().startOf('year')

      if (startDate.isSame(firstDayOfYear, 'day')) {
        return `${firstDayOfYear.format('Y')} calendar`
      }

      const firstYear = startDate.format('Y')
      const secondYear = startDate
        .clone()
        .add(1, 'year')
        .format('Y')

      return `${firstYear}/${secondYear} calendars`
    },

    async update(calendar) {
      this.isUpdatingCalendar = true

      try {
        await Calendars.update(calendar)

        this.success('Calendar updated successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      await this.fetchCalendars()

      this.fetchUser()

      this.isUpdatingCalendar = false
    },

    async destroy(calendar) {
      if (this.calendars.length() === 1) {
        return this.error('You need to keep at least 1 calendar.')
      }

      const confirmed = await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (!confirmed) return

      this.deletingCalendar = calendar

      try {
        await Calendars.delete(calendar)

        await this.fetchCalendars()

        this.fetchUser()

        this.success('Calendar deleted successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.deletingCalendar = null
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      this.loading = true

      try {
        await this.saveAutomaticCalendarSettings()

        await this.fetchUser()

        this.success('Calendar settings saved successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },
  },
}
</script>
