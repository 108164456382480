<template>
  <div
    class="tw-relative tw-mx-4 tw-mb-8 tw-border ta-border-gray-104 tw-bg-gray-100 tw-rounded-lg tw-overflow-hidden"
    style="min-height: 14.6rem"
  >
    <SpinnerButton
      v-if="!isCurrentYearCalendar"
      :disabled="disabled"
      :loading="isDeleting"
      :spinner-only="true"
      :spinner-classes="['tw-h-6 tw-w-6 tw-text-gray-500']"
      theme="transparent"
      class="tw-absolute tw-top-0 tw-right-0 tw-p-4"
      data-cy="delete-calendar"
      @click="$emit('delete', calendar)"
    >
      <svg-icon
        name="close-outline"
        class="tw-w-6 tw-h-6 tw-cursor-pointer tw-text-gray-500 hover:tw-text-red-500"
      />
    </SpinnerButton>

    <div class="tw-px-6 tw-pt-8 tw-pb-8 tw-text-gray-800">
      <div class="form-group" data-cy="calendar-start-date">
        <div class="tw-w-full tw-px-3">
          <label class="form-label">
            Start Date
          </label>
          <p class="tw-font-semibold" v-text="calendar.start_date" />
        </div>
      </div>

      <div class="form-group">
        <div class="tw-w-full tw-px-3" data-cy="calendar-end-date">
          <label class="form-label">
            End Date
          </label>
          <p class="tw-font-semibold" v-text="calendar.end_date" />
        </div>
      </div>

      <div class="form-group tw-mb-0">
        <div class="tw-w-full tw-px-3">
          <input
            :id="name"
            :checked="calendar.hidden"
            :aria-checked="calendar.hidden"
            :value="calendar.hidden"
            :name="name"
            :disabled="disabled"
            class="magic-checkbox"
            type="checkbox"
            data-cy="hidden-calendar"
            @change="update"
          />
          <label :for="name" class="tw-select-none magic-checkbox-label">
            <div class="tw-flex tw-items-center">
              <span>Hidden</span>
              <extra-info icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  Hides the calendar from non-admin users. Users are not able to
                  request leave or view allowances from hidden calendars
                </div>
              </extra-info>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import FormatDate from '../mixins/FormatDate'
const ExtraInfo = () => import('../components/ExtraInfo')
import SpinnerButton from '@/components/SpinnerButton'

export default {
  name: 'CalendarCard',

  components: { ExtraInfo, SpinnerButton },

  mixins: [FormatDate],

  props: {
    calendarData: {
      type: Object,
      required: true,
    },

    deletingCalendar: {
      type: Object,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    name() {
      return `hidden${this.calendar.id}`
    },

    calendar() {
      const calendar = this.calendarData
      return {
        ...calendar,
        start_date: this.formatDateFromIsoToDayReadableNumberMonthYear(
          moment.utc(calendar.start_date),
          'utc'
        ),
        end_date: this.formatDateFromIsoToDayReadableNumberMonthYear(
          moment.utc(calendar.end_date),
          'utc'
        ),
      }
    },

    isCurrentYearCalendar() {
      return (
        moment
          .utc(this.calendarData.start_date)
          .isSameOrBefore(moment().utc(true)) &&
        moment.utc(this.calendarData.end_date).isSameOrAfter(moment().utc(true))
      )
    },

    isDeleting() {
      if (!this.deletingCalendar) {
        return false
      }

      return this.deletingCalendar.id === this.calendarData.id
    },
  },

  methods: {
    update() {
      this.$emit('update', {
        ...this.calendar,
        hidden: !this.calendar.hidden,
      })
    },
  },
}
</script>
