<template>
  <tr>
    <td data-cy="employee-name">{{ approver.employee_full_name }}</td>
    <td>{{ approver.employee_code }}</td>
    <td data-cy="approver-name">{{ approver.approver_full_name }}</td>
    <td>{{ approver.approver_code }}</td>
    <td data-cy="source-type">{{ approver.source }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ApproverReportTableRow',

  props: {
    approver: {
      type: Object,
      required: true,
    },
  },
}
</script>
